import React, { Component } from 'react';
import { connect } from "react-redux";
import Img from 'react-image';
import logo from 'public/static/img/logo.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDollarSign, faGlobe } from "@fortawesome/free-solid-svg-icons";


//	This maps the data store to local variables.
const mapStateToProps = state  => {
	return {
	  footer_text: state.footer_text
	};
};

//	Main Home Class
class Footer extends Component {

	constructor(props) {

		super(props);
		
	}

	loadRegister = (event) => {
		this.props.dispatch({type: 'REGISTER'});
	};

	render() {
	  return (
		<footer>
			<div className="footer_centered">
				<div className="footer_left">
					<div className="footer_left_top">
						<a href="/"><Img src={logo} className="footer_logo" alt="My Secret Cams" /></a>
					</div>
					<div className="footer_left_bottom">
						<h6>Warning! This website contains live asian girls naked on cam. Sexually explicit content with highly addictive personalities. Use of this Website is not permitted where prohibited by law.</h6>
					</div>
				</div>
				<div className="footer_right">
					<div>
						<h3>Get Involved <FontAwesomeIcon icon={faDollarSign} /></h3>
						<a href="/user/broadcast/">Broadcast Yourself</a>
						<a href="/user/affiliate/">Promote Us</a>
						<a onClick={this.loadRegister}>Register</a>
					</div>
					<div>
						<h3>Similar Sites <FontAwesomeIcon icon={faGlobe} /></h3>
						<a href="https://asiagirlcams.com">Asia Cam Girls</a>
						<a href="https://www.asianpornwebcams.com">Asian Porn Webcams</a>
					</div>
				</div>
			</div>
			<div className="footer_end">Disclaimer: All members and people appearing on this site are 18 years of age or older.</div>
		</footer>
	  );
	}
}

//	Export with redux connections.
export default connect(mapStateToProps)(Footer);