import React, { Component } from 'react';
import UserContainer from 'src/components/parts/UserContainer';
import {Helmet} from 'react-helmet';
import { connect } from "react-redux";
import { getChatrooom, setText, setUsers } from "src/cms.js";
import Img from 'react-image';
import closechat_icon from 'public/static/img/closechat_icon.png';
import girl_circle from 'public/static/img/girl_circle.png';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExpand, faUserSecret, faEnvelope, faDonate } from "@fortawesome/free-solid-svg-icons";
import Header from 'src/components/parts/Header';

//	This maps the data store to local variables.
const mapStateToProps = state  => {
	return {
	  meta_title: state.meta_title,
	  meta_description: state.meta_description,
	  content: state.content,
	  currentUser:state.currentUser,
	  top_text: state.top_text
	};
};

//	Main Home Class
class Chatroom extends Component {

	constructor(props) {

		super(props);

		var width = window.innerWidth || document.documentElement.clientWidth || document.getElementsByTagName('body')[0].clientWidth;
		var height = window.innerHeight|| document.documentElement.clientHeight|| document.getElementsByTagName('body')[0].clientHeight;
		
		if( width > 1500 ) width = 1500;

		height = width * 0.5625;

		this.state = {width: width, height: height}

		this.generateRandomAge = this.generateRandomAge.bind(this);

	}

	//	On Load
	componentDidMount() {

		getChatrooom( this.props );

		setText(this.props);

		
	}


	componentDidUpdate(prevProps, prevState) {

		//	Make sure there was a change in pages
		if( this.props.location.pathname !== prevProps.location.pathname) {

			this.props.dispatch({type: 'GET_ALL_USERS_RESET'});	// after getting initial users will allow for when scroll happens will get all users again

			this.props.dispatch({type: 'RESET_SHOW_USERS'});
			
			getChatrooom(prevProps)

			setUsers(prevProps)

			setText(prevProps)

		} else {

			//window.scrollTo({ top:0, behavior: 'smooth' });
			window.scrollTo({ top:0 });

		}
		
	}

	renderProfileImage = () => {

		// Check if it is empty.
		if( typeof this.props.currentUser.picture_url === 'undefined' ) {
			return '';
		}

		// Image is there return.
		return(
			<Img src={document.location.protocol+'//'+document.domain+this.props.currentUser.picture_url} className="chatroom-profile-picture" />
		);
		
	}

	generateRandomAge = () => {
		return Math.floor(Math.random() * (30 - 19)) + 19;
	}


	render() {
	//console.log( document.location.protocol+'//'+document.domain+this.props.currentUser.iframe );

	  return (
		<div>
		    <Header />
		    <div className="container">
				<div id="liveroom_container">
					<div className="chatroom">
						<a href="/" className="close_chat_icon" title="Exit Chatroom"><Img src={closechat_icon} alt="Close Chat Icon" /></a>
						<iframe src={this.props.currentUser.iframe} height={this.state.height} width={this.state.width} title={this.props.currentUser.username} />
					</div>
					<div className="chatroom-contentwrap">
						<div className="chatroom-current-model">
							<div className="current-model-box">
								<Img src={girl_circle} className="girl_circle" alt="Girl Icon" />
								{this.props.currentUser.name}
							</div>
							<a className="full_screen_chat" href={this.props.currentUser.live_room_url} title="Go Full Screen"><FontAwesomeIcon icon={faExpand} /></a>
						</div>
						<div className="profile_heading">
							<h1>PROFILE INFO:</h1>
							<div className="profile_actions">
								<a href={this.props.currentUser.live_room_url} className="room_btn tip" title="Tip Me">TIP <FontAwesomeIcon icon={faDonate} /></a>
								<a href={this.props.currentUser.live_room_url} className="room_btn message" title="Message Me">MESSAGE <FontAwesomeIcon icon={faEnvelope} /></a>
								<a href={this.props.currentUser.live_room_url} className="room_btn private" title="Private Chat">PRIVATE <FontAwesomeIcon icon={faUserSecret} /></a>
							</div>
						</div>
						<div className="chatroom-info">
							<a className="recent_shot" href={this.props.currentUser.live_room_url}>
								{this.renderProfileImage()}
								<a className="recent_shot_gallerylink" href={this.props.currentUser.live_room_url}>GALLERY</a>
							</a>
							<div><h2>Age:</h2><p>{this.props.currentUser.age == 0 ? this.generateRandomAge() : this.props.currentUser.age} years old</p></div>
							<div><h2>Ethnicity:</h2><p>{this.props.currentUser.ethnicity === 'none' ? 'Ask' : this.props.currentUser.ethnicity}</p></div>
							<div><h2>Body Type:</h2><p>{this.props.currentUser.body_type === 'none' ? 'Average' : this.props.currentUser.body_type}</p></div>
							<div><h2>Chatting Now:</h2><p>{this.props.currentUser.chatters == 0 ? Math.floor(Math.random() * 100) : this.props.currentUser.chatters}</p></div>
							<div><h2>Country Code:</h2><p>{this.props.currentUser.country_code === '' ? 'US' : this.props.currentUser.country_code}</p></div>
							<div><h2>I Speak:</h2><p>{this.props.currentUser.languages}</p></div>
							<div><h2>Turn On:</h2></div>
							<div><p>{this.props.currentUser.turn_ons == '' ? 'Horny guys' : this.props.currentUser.turn_ons}</p></div>
							<div><h2>Turn Off:</h2></div>
							<div><p>{this.props.currentUser.turn_offs == '' ? 'Negative vibes' : this.props.currentUser.turn_offs}</p></div>
							<div><h2>About Me:</h2></div>
							<div><p>{this.props.currentUser.bio == '' ? 'I love to get naked in private. Join me!' : this.props.currentUser.bio}</p></div>
						</div>
						<h4>More Shows Like <span className="highlight">{this.props.currentUser.name}</span></h4>
						<Helmet>
							<title>{this.props.meta_title}</title>
							<meta name="description" content={this.props.meta_description} />
						</Helmet>
					</div>
				</div>
				<div className="center_chatmodels"><UserContainer /></div>
			</div>
		</div>
	  );
	}
}

//	Export with redux connections.
export default connect(mapStateToProps)(Chatroom);


