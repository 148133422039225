//
//	The main user container element
//
import React, { Component } from 'react';
import User from 'src/components/parts/User';
import { connect } from "react-redux";
import { setUsers } from "src/cms.js";

const mapStateToProps = state  => {
	return {
		users: state.users,
		usersVisible: state.usersVisible,
		usersTotal: state.usersTotal,
		showItems: state.showItems,
		userOrder: state.userOrder,
		usersCompleteLoad: state.usersCompleteLoad,
		showMoreDelay: state.showMoreDelay,
		top_text: state.top_text
	};
};

class UserContainer extends Component {

	constructor(props) {

		// showSideBar
		super(props);

	}

	//	On Load
	componentDidMount() {

		// temp commented to work with only getting current user..
		setUsers( this.props );
		
		// Call back to start track scrolling
		document.addEventListener('scroll', this.trackScrolling);

	}

	//	On Remove
	componentWillUnmount() {

		//	stop tracking scolling
		document.removeEventListener('scroll', this.trackScrolling);

	}
	
	// Function to track when user start scrolling.
	trackScrolling = () => {
		console.log( 'scrolling' );
		// This is where we will trigger a complete load of users. 
		if( !this.props.usersCompleteLoad ){
			console.log( 'getting all started.' );
			this.props.dispatch({type: 'GET_ALL_USERS_STARTED'});
			// Get all of the models
			setUsers( this.props );
			console.log( 'getting all users set.' );
		}

		const userBlockBottom = document.getElementsByClassName('users')[0].getBoundingClientRect().bottom;
		
		// 1. When user is 1x height of device away from bottom.
		// 2. Make sure more loading only happens once every second.
		// 3. do not show if max users already loaded.
		// console.log( userBlockBottom + " <= " + (window.innerHeight * 2) ); 
		console.log( "show more delay: " + this.props.showMoreDelay );
		console.log( this.props.usersVisible.length + " visible, showing " + this.props.users.length  );


		if( userBlockBottom <= (window.innerHeight * 2) && !this.props.showMoreDelay && this.props.usersVisible.length !== this.props.users.length ) {
			console.log( 'more users' );
			// Don't load more than once every 2 seconds
			this.props.dispatch({type: 'MORE_USERS'});

			// 	Issue was fixed but as a note -> Issue still is if we scroll very fast and hit bottom of page before 1 second it won't load more. ( this is only on desk top though since scroll will still trigger even more on mobile.)
			//	Delay loading additional users for 2 seconds
			setTimeout(() => { this.props.dispatch({type: 'MORE_USERS_DONE'}); }, 1000 );
			
		}
	};

	//	Display user box
	render() {
	  return (
		<div className="users_wrapper">
          <div className="users">
	  	    { window.innerWidth > 768 ? <h1 className="users_heading">{this.props.top_text} ({this.props.usersTotal} models)</h1> : null }
		    {this.props.usersVisible.map( (user) => 
				<User key={user.id} info={user} />
            )}
          </div>
		  {this.props.loadingMessage ? <div>Loading...</div> : null}
        </div>
	  );
	}
}


export default connect(mapStateToProps)(UserContainer);