//
//	Filters Desktop
//
import React, { Component } from 'react';
import { connect } from "react-redux";

const mapStateToProps = state  => {
	return {
		users: state.users,
		usersVisible: state.usersVisible,
		usersTotal: state.usersTotal,
		showItems: state.showItems,
		userOrder: state.userOrder
	};
};

class FiltersDesktop extends Component {

	constructor(props) {

		super(props);

	}


	//	Order users by specific..
	orderUsers = (event) => {
		this.props.dispatch({type: 'ORDER_USERS', order:event.target.attributes.getNamedItem('data-sort').value});
	};


	render() {

		return (
            <div className="filters_fordesktop">
                <button className="filter_cams" id={this.props.userOrder == 'ATOZ' ? 'active' : ''} data-sort="ATOZ" onClick={this.orderUsers}>Name A-Z</button>
                <button className="filter_cams" id={this.props.userOrder == 'ZTOA' ? 'active' : ''} data-sort="ZTOA" onClick={this.orderUsers}>Name Z-A</button>
                <button className="filter_cams" id={this.props.userOrder == 'POPULAR' ? 'active' : ''} data-sort="POPULAR" onClick={this.orderUsers}>Popular</button>
                <button className="filter_cams" id={this.props.userOrder == 'OLDEST' ? 'active' : ''} data-sort="OLDEST" onClick={this.orderUsers}>Oldest</button>
                <button className="filter_cams" id={this.props.userOrder == 'YOUNGEST' ? 'active' : ''} data-sort="YOUNGEST" onClick={this.orderUsers}>Youngest</button>
            </div>
		);
	}

}

export default connect(mapStateToProps)(FiltersDesktop);