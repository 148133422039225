import React from 'react';
import { NavLink } from 'react-router-dom';
import FiltersDesktop from 'src/components/parts/FiltersDesktop';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins, faThumbsUp, faEye, faStar, faFilter, faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";

const mapStateToProps = state  => {
	return {};
};
class SideMenuDesktop extends React.Component {

	constructor(props) {

		super(props);

	}

	loadRegister = (event) => {
		this.props.dispatch({type: 'REGISTER'});
	};

	render() {
		return (
			<div id="sidemenu__desktop">
				<div className="sidemenu">
					<div className="sidemenu_top">
						<div className="credits_box">
							<a onClick={this.loadRegister} className="promotions" title="Join and Earn Free Tokens"><FontAwesomeIcon icon={faCoins} />PROMOTIONS</a>
							<h6>JOIN NOW<br />to get<br /> FREE tokens !</h6>	
						</div>
						<div className="sidemenu_top_options">
							<NavLink to="/tag/top-asian-sex-cams/" activeClassName="active_sidepage"><FontAwesomeIcon icon={faThumbsUp} /> TOP GIRLS</NavLink>
							<NavLink to="/tag/hd-asian-sex-cams/" activeClassName="active_sidepage"><FontAwesomeIcon icon={faEye} /> HD WEBCAMS</NavLink>
							<NavLink to="/tag/newest-asian-sex-cams/" activeClassName="active_sidepage"><FontAwesomeIcon icon={faStar} /> NEW GIRLS</NavLink>
						</div>
					</div>
					<div className="sidemenu_filters">
						<h1>FILTERS<FontAwesomeIcon icon={faFilter} /></h1>
						<FiltersDesktop />
					</div>
					<div className="sidemenu_categories">
						<ul id="side_list">
							<li className="side_list_title">AGE<FontAwesomeIcon icon={faChevronDown} /></li>
							<li><NavLink to="/tag/asian-teen-cam-sex/" activeClassName="active_sidepage">Teens 18+</NavLink></li>
							<li><NavLink to="/tag/twenty-year-olds/" activeClassName="active_sidepage">Twenties (20 - 29)</NavLink></li>
							<li><NavLink to="/tag/thirty-year-olds/" activeClassName="active_sidepage">Thirties (30 - 39)</NavLink></li>
							<li><NavLink to="/tag/forty-year-olds/" activeClassName="active_sidepage">Forties (40 - 49)</NavLink></li>
							<li><NavLink to="/tag/mature-asian-cam-sex/" activeClassName="active_sidepage">Mature 50+</NavLink></li>
							<li className="side_list_title">BODY TYPE<FontAwesomeIcon icon={faChevronDown} /></li>
							<li><NavLink to="/tag/athletic-asian-sex-cams/" activeClassName="active_sidepage">Athletic Models</NavLink></li>
							<li><NavLink to="/tag/bbw-asian-sex-shows/" activeClassName="active_sidepage">BBW Models</NavLink></li>
							<li><NavLink to="/tag/muscular-asian-sex-cams/" activeClassName="active_sidepage">Muscular Models</NavLink></li>
							<li><NavLink to="/tag/petite-asian-sex-cams/" activeClassName="active_sidepage">Petite Models</NavLink></li>
							<li className="side_list_title">BDSM<FontAwesomeIcon icon={faChevronDown} /></li>
							<li><NavLink to="/tag/live-asian-dominatrix/" activeClassName="active_sidepage">Dominatrix Shows</NavLink></li>
							<li><NavLink to="/tag/live-asian-slave/" activeClassName="active_sidepage">Slave Shows</NavLink></li>
							<li className="side_list_title">TAGS<FontAwesomeIcon icon={faChevronDown} /></li>
							<li><NavLink to="/tag/anal-cam-shows/" activeClassName="active_sidepage">Anal Sex</NavLink></li>
							<li><NavLink to="/tag/bit-tit-asian-cam-sex/" activeClassName="active_sidepage">Big Tits</NavLink></li>
							<li><NavLink to="/tag/blonde-asian-cam-sex/" activeClassName="active_sidepage">Blonde</NavLink></li>
							<li><NavLink to="/tag/brunette-asian-cam-sex/" activeClassName="active_sidepage">Brunette</NavLink></li>
							<li><NavLink to="/tag/redhead-asian-cam-sex/" activeClassName="active_sidepage">Redhead</NavLink></li>
							<li><NavLink to="/tag/foot-fetish-sex-cams/" activeClassName="active_sidepage">Foot Fetish</NavLink></li>
						</ul>
					</div>
				</div>
			</div>
		);
	}
}

//	Export with redux connections.
export default connect(mapStateToProps)(SideMenuDesktop);