import React from 'react';
import { NavLink } from 'react-router-dom';
import Search from 'src/components/parts/SearchBox';
import Img from 'react-image';
import icon_menu from 'public/static/img/icon_menu.png';
import icon_search from 'public/static/img/icon_search.png';
import x_icon from 'public/static/img/x_icon.png';
import { connect } from "react-redux";

const mapStateToProps = state  => {
	return {
	};
};


class MobileMenu extends React.Component {

	constructor(props) {

		super(props);
		this.state = {isOpen: false }
		this.handleMenuButton = this.handleMenuButton.bind(this);

	}

	//	Update menu state
	handleMenuButton(event) {
		this.setState({ isOpen: !this.state.isOpen });
	}


	loadRegister = (event) => {
		this.props.dispatch({type: 'REGISTER'});
		this.setState({ isOpen: false });
	};

	renderMobileMenuOpened = () => {

		return (
			<div className="menu_container">
				<div className="overlay" onClick={this.handleMenuButton}></div>
				<div id="menu">
					<div className="menu_top">
						<div className="free_account_btn"><a onClick={this.loadRegister}>FREE ACCOUNT</a></div>
						<div className="close_menu_btn" onClick={this.handleMenuButton}><Img src={x_icon} className="close_menu_icon" alt="Close Menu Icon" /></div>
					</div>
					<Search />
					<div className="flex_links3">
						<NavLink to="/tag/top-asian-sex-cams/" activeClassName="active_menulink" onClick={this.handleMenuButton}>POPULAR</NavLink>
						<NavLink to="/tag/hd-asian-sex-cams/" activeClassName="active_menulink" onClick={this.handleMenuButton}>HD</NavLink>
						<NavLink to="/tag/newest-asian-sex-cams/" activeClassName="active_menulink" onClick={this.handleMenuButton}>NEW</NavLink>
					</div>
					<ul className="menu_list">
						<li className="category_heading">AGE</li>
						<li><NavLink to="/tag/asian-teen-cam-sex/" activeClassName="active_menulink" onClick={this.handleMenuButton}>TEEN 18+</NavLink></li>
						<li><NavLink to="/tag/twenty-year-olds/" activeClassName="active_menulink" onClick={this.handleMenuButton}>TWENTIES (20 - 29)</NavLink></li>
						<li><NavLink to="/tag/thirty-year-olds/" activeClassName="active_menulink" onClick={this.handleMenuButton}>THIRTIES (30 - 39)</NavLink></li>
						<li><NavLink to="/tag/forty-year-olds/" activeClassName="active_menulink" onClick={this.handleMenuButton}>FORTIES (40 - 49)</NavLink></li>
						<li><NavLink to="/tag/mature-asian-cam-sex/" activeClassName="active_menulink" onClick={this.handleMenuButton}>MATURE 50+</NavLink></li>
						<li className="category_heading">BODY TYPE</li>
						<li><NavLink to="/tag/athletic-asian-sex-cams/" activeClassName="active_menulink" onClick={this.handleMenuButton}>ATHLETIC</NavLink></li>
						<li><NavLink to="/tag/bbw-asian-sex-shows/" activeClassName="active_menulink" onClick={this.handleMenuButton}>BBW</NavLink></li>
						<li><NavLink to="/tag/muscular-asian-sex-cams/" activeClassName="active_menulink" onClick={this.handleMenuButton}>MUSCULAR</NavLink></li>
						<li><NavLink to="/tag/petite-asian-sex-cams/" activeClassName="active_menulink" onClick={this.handleMenuButton}>PETITE</NavLink></li>
						<li className="category_heading">BDSM</li>
						<li><NavLink to="/tag/live-asian-dominatrix/" activeClassName="active_menulink" onClick={this.handleMenuButton}>DOMINATRIX</NavLink></li>
						<li><NavLink to="/tag/live-asian-slave/" activeClassName="active_menulink" onClick={this.handleMenuButton}>SLAVE</NavLink></li>
						<li className="category_heading">TAGS</li>
						<li><NavLink to="/tag/anal-cam-shows/" activeClassName="active_menulink" onClick={this.handleMenuButton}>ANAL SEX</NavLink></li>
						<li><NavLink to="/tag/bit-tit-asian-cam-sex/" activeClassName="active_menulink" onClick={this.handleMenuButton}>BIG TITS</NavLink></li>
						<li><NavLink to="/tag/blonde-asian-cam-sex/" activeClassName="active_menulink" onClick={this.handleMenuButton}>BLONDE</NavLink></li>
						<li><NavLink to="/tag/brunette-asian-cam-sex/" activeClassName="active_menulink" onClick={this.handleMenuButton}>BRUNETTE</NavLink></li>
						<li><NavLink to="/tag/redhead-asian-cam-sex/" activeClassName="active_menulink" onClick={this.handleMenuButton}>REDHEAD</NavLink></li>
						<li><NavLink to="/tag/foot-fetish-sex-cams/" activeClassName="active_menulink" onClick={this.handleMenuButton}>FOOT FETISH</NavLink></li>
					</ul>
				</div>
			</div>
		)
	}


	renderMobileMenuClosed = () => {

		return(
			<div className="menu_openers">
				<div className="open_search" onClick={this.handleMenuButton}>
					<Img src={icon_search} className="header_icon" alt="Search Icon" />
				</div>
				<div className="open_menu" onClick={this.handleMenuButton}>
					<Img src={icon_menu} className="header_icon" alt="Menu Icon" />
				</div>
			</div>
		)

	}

	render() {

		return (
			<div>
				{this.state.isOpen ?  this.renderMobileMenuOpened() : this.renderMobileMenuClosed()}
			</div>
		);
	}
}

export default connect(mapStateToProps)(MobileMenu);