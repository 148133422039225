import React from "react";
import App from "src/App";
import { Provider } from "react-redux";
import {configureStore} from "src/store.js";

const Root = props => {
  
  // Define the store
  const store = configureStore();
  
  // wrap redux store in provider so it will be passed globally as a prop.
  return (
    <Provider store={store}>
      <App />
    </Provider>
  );
}

export default Root;